<span class="rating"
      *ngIf="rating && rating.value"
      [ngClass]="{
      '_green': green,
      '_orange': orange,
       '_red' : red,
       '_negative': rating.direction === 'down'}">
    {{ value }}
    <mat-icon class="arrow" *ngIf="rating.direction !== 'none'" svgIcon="radius_arrow_up"></mat-icon>
    <span class="extra-word">{{extraWord}}</span>
</span>

<span class="rating _empty"
      *ngIf="rating && !rating.value">-.-
</span>
